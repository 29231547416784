.module-list {
  .material-table__toolbar {
    padding: 0 !important;
    min-height: 48px !important;

    .MuiButtonBase-root {
      height: 24px !important;
      width: 18px !important;
    }
  }
}
