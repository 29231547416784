.detail-view-table {
  .material-table .toolbar {
    display: none;
  }

  .material-table .toolbar h6 {
    display: none;
  }

  .MuiTableCell-root {
    padding: 2px !important;

    span {
      padding: 2px !important;
    }
  }
  /*
  overflow-y: auto;
  height: 200px;

  .material-table .toolbar {
    display: none;
  }

  thead th {
    position: sticky;
    top: 0px;
  } */

  //height: 400px;
  //overflow-y: auto;
}

.detail-view-field .material-table > .MuiPaper-root {
  div:nth-child(2) {
    max-height: 500px;
  }
}
