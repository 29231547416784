.material-table{
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  & > .MuiPaper-root div:nth-child(2){
    //fix FF issue
    z-index: 0;
    position: relative;
  }
  .toolbar{
    display: flex;
    justify-content: space-between;
    z-index: 11;
  }
  .MuiPaper-root{
    .MuiToolbar-root{
      .searchTool{
        display: flex;
        align-items: center;
        z-index: 1;
      }
    }
  }

  .search-block {
    margin-right: 50px;
  }

  .referenceLink {
    cursor: pointer;
    display: block;
    white-space: nowrap;
  }

  .selectBar {
    min-width: 250px;
    text-align: center;
    margin-right: 10px;
  }
}

.Toastify__toast-container{
  z-index: 99999;
  & .Toastify__toast--warning{
    background-color: #f1c40f ;
    color: black;
  }
  & .Toastify__toast--success {
    background-color: #88df6e;
    color: white;
  }
  & .Toastify__toast--error {
    background-color: #fc0000;
    color: white;
  }
  & .Toastify__toast--default {
    word-break: break-all;
    color: white;
    background: #88df6e;
    & a {
      color: blue
    }
  }
  & div.Toastify__progress-bar--default{
    background: rgbA(255, 255, 255, 0.8) !important;
  }
}
