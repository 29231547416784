.estimate-modal-dialog {
  font-size: 16px;

  .MuiDialogContent-root {
    padding: 26px 24px;
  }

  .MuiTypography-root {
    color: black;
  }

  .MuiDialog-container .MuiDialogActions-root .btn {
    width: auto;
    height: auto;
  }
}