.user-page {
    .table-cell-icon{
      margin-left: 10px;
      cursor: pointer;
    }
}

.icon-add {
  width: 30px;
  height: 30px;

  &.MuiIconButton-root {
    color: black;
    background-color: rgba(0, 0, 0, 0.11);

    &:hover {
      background-color: rgba(0, 0, 0, 0.11);
    }
  }
}
