.dw-managements-page {
  display: flex;
  flex-direction: column;

  .MuiPaper-root {
    div[class^='Component-horizontalScrollContainer'] > div > div {
      height: 350px;
    }
  }

  &__box {
    display: flex;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    border-radius: 4px;
    padding: 20px 0;

    &-title {
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.75;
      color: rgb(100, 103, 119);
    }

    &-fields {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .horizontal {
        label {
          width: 12%;
        }
    
        .select {
          width: 85%;
        }
      }
    }
  }

  .table-cell-icon {
    margin-left: 10px;
    cursor: pointer;
  }
  .module {
    margin: 20px 40px;
    width: auto;
    th,
    td {
      border-bottom-color: rgba(224, 224, 224, 0.25);
    }
    td,
    th.module-value {
      border-right: 1px solid rgba(224, 224, 224, 0.5);
    }
    tbody th {
      padding-left: 30px;
    }
  }
  .solar-module-menu {
    cursor: pointer;
  }

  .material-table > div > div:nth-child(2) > div > div > div {
    box-shadow: none !important;
  }

  .props-additional {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 0;
    & > * {
      margin-right: 16px;
      margin-bottom: 0;
    }
    & .MuiGrid-container {
      width: auto;
    }
  }

  &-footer {
    margin-top: 20px;
  }

  [value=''] {
    div {
      width: 100% !important;
      height: 20px;
    }
  }

  &_padding {
    padding: 20px;
  }
}
