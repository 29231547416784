.blank-estimate-page {
  padding-bottom: 10px;

  .loader {
    height: calc(100vh - 100px);

    .load {
      height: 100% !important;
    }
  }

  .card__title_margin-left {
    margin-left: 10px;
  }

  .line {
    border: 1px solid rgba(224, 224, 224, 1);
  }
}
