.confirm-dialog {
  .MuiDialogContent-root:first-child {
    padding: 20px;
    text-align: center;
    font-weight: 450;
  }

  &__container, .MuiDialogContent-root, .MuiDialog-paper {
    overflow-y: initial;
  }

  &__container, .MuiDialogContent-root {
    padding: 0 17px 0 12px;
  }

  &-actions-dialog__container {
    min-width: '550px';
  }

  label {
    width: 10%;
  }

  .select {
    width: 88%;
  }
}