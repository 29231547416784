.rootSelectList {
  max-height: 700px;
  margin-bottom: 15px;

  .table {
    border-collapse: separate;
    border-spacing: 0;
  }

  .MuiTableRow-head th {
    position: sticky;
    top: 0;
    background: #d6d6d6;
    border-bottom: solid 2px black;
    text-align: right;
    max-width: 300px;
    text-transform: capitalize;
    &:first-child {
      width: 200px;
      text-align: left;
    }
  }

  .MuiTableBody-root tr {
    transition: 0.4s;

    &:nth-of-type(odd) {
      background: #f6f6f6;
    }

    &:last-child td,
    &:last-child th {
      border: 0;
    }

    &:hover {
      background: #d6d6d6;
      cursor: pointer;
    }

    &.selected {
      background: #d6d6d6;
    }

    td {
      max-width: 300px;
    }
  }
}
.MuiDialogTitle-root {
  .searchInput {
    left: 25px;
    .MuiInput-root {
      width: 350px;
    }
  }
  .closeIcon {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    color: #9e9e9e;

    &:hover {
      color: #000;
    }
  }
}

.MuiDialog-container {
  .MuiDialogActions-root {
    padding: 20px 35px 20px 5px;

    .btn {
      margin-right: 0 !important;
    }
    
    .btn.btn-lg {
      padding: 12px 45px;
    }

    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.MuiDialog-container {
  .MuiDialogActions-root{
    justify-content: space-between;

    &:has(button:only-child){
      justify-content: flex-end;
    }

    & button:first-child{
      margin-left: 15px;
    }
  }

  a {
    text-transform: none;
    height: 27px;
    font-size: 17px;
    font-weight: 400;
  }
}

.alignCenter{
  text-align: center;

  span {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}

