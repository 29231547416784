.material-table {
  .searchTool {
    div:nth-child(2) {
      z-index: unset;
    }
    .filterBox {
      .filter-form-group {
        justify-content: flex-end;
        &:has(.additional-checkbox)>span{
          margin-top: 7px;
        }
        .filter-field {
          width: 100%;
          input {
            &:focus {
              border-color: #0a4f77;
            }
          }

          .react-select__menu-list > div:first-child {
            min-height: 35px;
            background-color: #ffffff;
            &:hover {
              background-color: #deebff;
            }
            &:active {
              background-color: #b2d4ff;
            }
          }

          &>div:has(.additional-checkbox) {
            display: flex;
            justify-content: space-between;
            input {
              width: 60%;
            }
          }
        }
      }
      .filter-box-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 0;
      }
      .additional-checkbox {
        color: #646777;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 5px 0;
        font-size: 11px;
        label {
          margin-left: 5px;
          margin-right: -5px;
          height: 18px;
        }
      }
    }
  }
}
