.toolbar {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .btn {
    margin-right: 0 !important;
  }
  
  .btn.btn-md {
    padding: 12px 25px;
  }

  .btn.btn-lg {
    padding: 12px 45px;
  }
}
