.estimate-header {
  header {
    min-height: 48px;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    background: #ffffff;
    color: #646777;
    box-shadow: none;

    .estimate-title {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      white-space: nowrap;

      &-center {
        margin-left: 15px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 500;
        white-space: nowrap;
      }

      &-end {
        display: flex;
        justify-content: flex-end;
        font-weight: bold;
      }

      .note {
        padding: 5px;
        width: 100%;
        overflow: hidden;
        word-wrap: break-word;
        white-space: pre-line;
        text-align: center;
      }

      .block {
        display: flex;
        justify-content: center;
      }
    }

    .note {
      background-color: rgba(122, 235, 202, 1);
      color: #000000;
      border-radius: 5px;
    }
  }
}

.deleteDialog {
  .MuiDialog-container {
    .MuiDialogActions-root.MuiDialogActions-spacing {
      justify-content: end;
    }
  }
}

.export-button {
  border: 0;
  color: #0a4f77;
  background-color: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  padding: 6px 8px;

  &:hover {
    background-color: rgba(62, 163, 252, 0.04);
  }

}
