.list-fields {
  &-list {
    border-top: 2px solid #004D7A;
    border-bottom: 2px solid #004D7A;
  }

  &-list.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-progress {
    display: flex;
    color: #0a4f77;
    justify-content: center;
    align-items: center;
  }
}
