.card-body .detail-view-title {
  margin-bottom: 15px;

  h6 {
    padding-left: 0;
  }
}

.detail-view {
  padding: 0;
}

.detail-view-tools {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &:has(button:only-child) {
    justify-content: end;
  }
}

.detail-view-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.detail-view-form {
  .detail-view-form-group {
    justify-content: flex-end;
    .detail-view-field {
      width: 100%;

      input {
        &:focus {
          border-color: #0a4f77;
        }
      }
    }
    .detail-view-field-highlight-error input {
      border-color: #f44336;
    }

    .detail-view-field-error {
      color: #f44336;
    }

    .label_margin-0 {
      margin: 0 !important;
    }
  }

  &__col {
    padding: 0 !important;

    &-extended {
      border-right: 1px solid rgba(224, 224, 224, 1);

      padding: 0 35px !important;

      &-last {
        padding-right: 0 !important;
        border-right: 0;
      }

      &-first {
        padding-left: 0 !important;
      }

      .detail-view-form-group {
        span {
          width: 21% !important;
        }

        &>div {
          width: 79% !important;
        }

        .react-select__control {
          width: 100% !important;
        }
      }
    }

    &-fields {
      margin-top: 30px;

      .detail-view-form-group {
        span:not(tfoot span, :has(svg)) {
          width: 7% !important;
        }

        &>div {
          width: 93% !important;
          min-height: 20px;
        }

        .react-select__control {
          width: 100% !important;
        }
      }
    }
  }

  &__accordion {
    width: 99%;
    margin-bottom: 10px;

    box-shadow: 0 1px 1px -1px rgba(0,0,0,0.2), 0 0 0 0 rgba(0,0,0,0.0), 0 1px 1px 0 rgba(0,0,0,0.12) !important;
  }

  .MuiAccordionSummary-root  {
    padding: 0;
  }

  .MuiAccordionDetails-root {
    padding: 20px 0;
  }

  &__toolbar {
    margin: 30px 10px 0 0;
  }
}

.props-additional {
  z-index: 1;
}
