.container-address {
  label {
    line-height: 1.5;
    font-weight: normal;
    width: 25%;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}
