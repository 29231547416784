.reports-custom-page {
  padding-bottom: 10px;

  .loader {
    height: calc(100vh - 100px);

    .load {
      height: 100% !important;
    }
  }

  .card__title_margin-left {
    margin-left: 10px;
  }

  .line {
    border: 1px solid rgba(224, 224, 224, 1);
  }

  .reports-custom-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    white-space: nowrap;

    &-center {
      margin-left: 15px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      white-space: nowrap;
    }

    &-end {
      display: flex;
      justify-content: flex-end;
    }

    .block {
      display: flex;
      justify-content: center;
    }

    &__not_supported {
      font-weight: 700;
      font-style: italic;
    }
  }

  .accordion {
    box-shadow: none;
    border: 1px solid rgba(224, 224, 224, 1);

    .reports-custom-title {
      align-items: start;
      width: auto;
      color: #646777;
    }
  }

  .filter-select-data {
    display: flex;
    align-items: center;

    &__title {
      font-weight: 700;
      margin-left: 10px;
    }
  }

  .card-body {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }
}

.reports-custom-progress {
  display: flex;
  color: #0a4f77;
  justify-content: center;
  align-items: center;
}