.input-group {
  .is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    box-shadow: none;
  }

  .invalid-feedback {
    width: 100%;
    font-size: 0.7rem;
    font-weight: 300;
    color: #dc3545;
  }

  .label_left {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}

.pc-input-tip {
  .measurement-input__tipDefault {
    position: relative;
    color: #aaa;
    font-weight: 300;
    top: 10px;
  }
  .measurement-input__tipAttention {
    color: #aaa;
    position: relative;
    font-weight: 400;
    top: 15px;
  }
}

.form-group {
  .width-65perc {
    width: 65%;
  }

  .info-block {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
}
