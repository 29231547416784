.estimate-project-view {
  .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0;
  }

  .supports {
    font-weight: 500;
    .row {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      margin-top: 15px;
    }

    input {
      height: 32px !important;
    }
  }

  .center label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: bold !important;
    }
  }

  .btn-update {
    display: flex;
    justify-content: flex-end;
    margin-right: -10px;
  }
}

.card-body-flex {
  display: flex;
  flex-direction: column;
  flex: 1;

  .additional-components-center {
    justify-content: center;
  }

  .line {
    margin: 10px 0 15px 0;
  }

  .form-group-accessories {
    margin: 10px 0;
  }

  .last-modified-row {
    margin-top: auto;
  }

  .last-modified-text {
    text-align: center;
  }
}

.accessories-add-components {
  .separator {
    margin: 0;
    padding: 0;
    border: 1px solid #e0e0e0;
  }
  .accessories-add-components-label {
    color: #afafaf;
  }
}
