.table {
  border-collapse: separate;
  border-spacing: 0;

  thead .tr {
    z-index: 1;
  }

  th {
    position: sticky;
    top: 0;
    background: #d6d6d6;
    border-bottom: solid 2px black;
    text-align: right;
    max-width: 300px;
    text-transform: capitalize;
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid #e0e0e0 !important; // !important is needed to override the default border of MuiTableCell-root
      }
    }
  }
}
