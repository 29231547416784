.new-module {
  font-size: 16px;

  .dialog-title {
    display: flex;
    justify-content: space-between;

    .MuiDialogTitle-root {
      flex: 0 0 auto;
      margin: 0;
      padding: 16px 24px 0 24px;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    padding-bottom: 15px;
  }

  .title-bottom-border {
    border-bottom: solid 1px #efefef;
  }

  .buttons {
    display: flex;
    align-items: center;

    .btn {
      width: 100px;
    }
  }

  .line {
    border: 1px solid #e0e0e0;
  }

  .MuiDialog-container .MuiDialogActions-root button:first-child {
    margin-left: 0px;
  }

  .MuiDialog-container .MuiDialogActions-root .btn {
    height: auto;
  }

  .info {
    padding: 0 24px 16px 24px;
    font-weight: bold;
  }

  .horizontal label {
    width: 34%;
  }

  .horizontal .select {
    width: 63.8%;
  }

  .form-horizontal {
    .form-group {
      display: flex;
      align-items: center;
      margin-bottom: 0px;
    }
  }

  .form-control {
    padding-right: calc(1.5em + 0.75rem);
  }

  .pc-inline {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
  
    &.pull-right {
      margin-left: 10px;
    }

    .error {
      color: #ff6374;
    }
    .success {
      color: #009688;
    }
  }
}