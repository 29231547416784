.custom-header {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 5px;
  background: #ffffff;
  
  header {
    min-height: 48px;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    // background: #ffffff;
    color: #646777;
    box-shadow: none;

    padding: 10px 20px;

    .horizontal label {
      width: 20%;
      font-weight: 700 !important;
      color: hsl(0, 0%, 20%);
    }

    .form-group {
      margin-bottom: 0px;

      .input-group {
        justify-content: end;
      }
    }
  }

  &-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    white-space: nowrap;

    &-center {
      margin-left: 15px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      white-space: nowrap;
    }

    &-end {
      display: flex;
      justify-content: flex-end;
    }

    .block {
      display: flex;
      justify-content: center;
    }

    &__not_supported {
      font-weight: 700;
      font-style: italic;
    }
  }
}
