.autocomplete {
  display: flex;
  align-items: center;
  align-self: center;

  .label {
    width: 30%;
    margin-right: 10px;
  }

  &__select {
    width: 68%;

    border: 1px solid #ced4da;

    &.error {
      border: 1px solid #dc3545;

      .MuiAutocomplete-endAdornment {
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        box-shadow: none;
      }
    }

    .MuiInput-underline:after {
      border-bottom: 2px solid #0a4f77;
    }

    &:focus {
      border: 1px solid #0a4f77;
    }
  
    .MuiInput-underline:before {
      border: 0;
    }
  
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border: 0;
    }
  
    .MuiInput-underline:focus:not(.Mui-disabled):before {
      border-bottom: 1px solid #0a4f77;
    }
  
    .Mui-focused {
      border: 0;
    }
  
    .MuiAutocomplete-input {
      height: 28px;
      padding: 0.375rem 0.75rem !important;
    }
  }
}