
.submissions-page {
  .MuiTableSortLabel-root:has(div) {
    width: 100%;
    flex-direction: row;
    &>div {
      max-width: calc(100% - 1em);

      .cell-with-tooltip {
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        padding-left: 7px;
      }
    }
    .MuiTableSortLabel-icon {
      margin-left: 2px;
      margin-right: 0;
    }
  }
  .dw-submissions-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    box-shadow: 0 2px 1px -2px rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
      0 0 5px 0 rgba(0, 0, 0, 0.12);

    .dw-submissions-header-left {
      display: flex;
      align-items: center;
      font-weight: 600;
    }
  }

  .MuiToolbar-root {
    &>div:last-child {
      &:empty{
        width: 250px;
      }
    }
    .middleToolbar {
      display: flex;
      justify-content: space-between;
      button {
        background: #0a4f77;
        margin: 0 10px;
        color: white;
        padding: 12px 25px;
        span {
          color: white;
          max-width: min-content;
          line-height: 16px;
          font-size: 14px;
          text-transform: capitalize;
          padding: 1px 15px;
        }
      }
    }

    .MuiButtonBase-root:has(div.dropdown) {
      &:hover {
        background: none;
      }
    }

    .middleToolbar + div {
      z-index: 1;
    }
  }

  .MuiTableCell-root {
    padding: 0 5px;
    text-align: center;
    .cell-with-tooltip {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &>div:last-child {
      justify-content: center;
    }
    &:has(.MuiCheckbox-root), &:empty {
      max-width: 20px;
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: #0a4f77;
    }
    .Mui-disabled {
      color: rgba(0, 0, 0, 0.13);
    }
  }

  .MuiPaper-root {
    div[class^='Component-horizontalScrollContainer'] > div > div {
      height: 500px;
    }
  }

  .infoBox {
    font-weight: 700;
    font-size: 16px;
    top: 94px;
    right: 16px;
    width: 251px;
    background-color: #ebf0de;
    padding: 18px 47px 14px 0;
    z-index: 12;
    position: absolute;
    text-align: end;
    box-shadow: 1px 0 3px -2px rgba(0, 0, 0, 0.2),
    0px 1px 0 0 rgba(0, 0, 0, 0.14), 1px 0px 7px 2px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    .closeIcon {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .search-block {
    margin-right: 0;
  }

  .filterBox {
    width: 600px;
    top: 0;
    padding: 32px 50px;
    position: absolute;
    background-color: white;
    box-shadow: 1px 0 3px -2px rgba(0, 0, 0, 0.2), 0px 1px 0 0 rgba(0, 0, 0, 0.14), 1px 0px 7px 2px rgba(0, 0, 0, 0.12);
    .closeIcon {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

