.estimate-footer {
  padding: 25px;
  background: #ffffff;
  color: #646777;

  .documents {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 0;
  }

  .label {
    display: flex;
    align-items: center;

    .note {
      color: #646777;
    }
  }

  .documents-section {
    margin-left: 35px;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .document-list {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 10px;
      width: 100%;
      border: 1px solid #ced4da;
      border-radius: 2px;

      &>div>div {
        max-height: 250px;
        min-height: 85px;
        min-width: 252px;
      }

      .document-item>span {
        justify-content: flex-end;
      }
    }
  }

  .title {
    color: #646777;
    font-weight: 700;
    font-size: 14px;
  }

  .toolbar-buttons {
    display: flex;
    flex-direction: row;
    box-shadow: none;
    margin-top: 50px;

    .btn {
      width: 130px;
    }

    .estimate-title {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      white-space: nowrap;

      & span:last-child {
        margin-right: 15px;
      }
    }

    .estimate__toolbar_saves {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  .form-group {
    margin-bottom: 0 !important;
  }
}
