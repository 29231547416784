.table-tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .right-table-tool {
    &.with-action-tool {
      display: flex;
      min-width: 180px;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.MuiDialogContent-root {
  .pagination-info, .pagination-select {
    color: black;
  }
}

.form-control.table__edit-form {
  border: none;
  width: 100%;
  height: 100%;
  max-width: 75px;
}
