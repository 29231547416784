.dw-request-actions-dialog {
  &__container, .MuiDialogContent-root, .MuiDialog-paper {
    overflow-y: initial;
  }

  &__container, .MuiDialogContent-root {
    padding: 0 17px 0 12px;
  }

  label {
    width: 10%;
  }

  .select {
    width: 88%;
  }
}