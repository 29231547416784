.smm-page {
  .table-cell-icon {
    margin-left: 10px;
    cursor: pointer;
  }
  .module {
    margin: 20px 40px;
    width: auto;
    th,
    td {
      border-bottom-color: rgba(224, 224, 224, 0.25);
    }
    td,
    th.module-value {
      border-right: 1px solid rgba(224, 224, 224, 0.5);
    }
    tbody th {
      padding-left: 30px;
    }
  }
  .solar-module-menu {
    cursor: pointer;
  }

  .material-table > div > div:nth-child(2) > div > div > div {
    box-shadow: none !important;
  }
  .data-sheet-url {
    svg {
      font-size: 15px;
      margin-left: 5px;
      margin-top: -3px;
    }
  }
  .props-additional {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 0;
    & > * {
      margin-right: 16px;
      margin-bottom: 0;
    }
    & .MuiGrid-container {
      width: auto;
    }
  }

  .solar-modules {
    &__middletoolbar {
      display: flex;
      gap: 20px;
    }

    &__buttons-sync {
      display: flex;
      gap: 10px;

      .btn-primary {
        border-color: #004D7A !important;
        background-color: #004D7A !important;
      }
    }
  }

  .detail-view-table .MuiTable-root {
    .MuiInputBase-input, .MuiIconButton-root {
      height: 30px;
      padding: 0 10px;
      margin: 0;
      text-align: center;
    }

    .MuiIcon-root {
      font-size: 1.2rem;
      padding: 0 !important; // override the padding for the IconButton with !important
    }
    .MuiTableCell-body {
      height: 32px;
      text-align: center;

      &:has(div){
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }

      &:has(div>div){
        display: table-cell;
      }
    }
  }

}
