@mixin offset-left-data-picker {
  .date-picker-box {
    right: 0;
    left: auto;
    .react-datepicker-popper {
      left: -50px !important;
    }
  }
}

.analytic-page {
  .pie-chart {
    margin: -10px auto 0;
    width: 100%!important;
    max-height: 350px;
  }

  h2 {
    position: relative;
    top: 6px;
    z-index: 1;
  }

  .analytic-KPI {
    margin: 0;
    &-group-title {
      margin: 25px 0 7px -10px;
      h3 {
        font-weight: 700;
      }
    }
  }

  .analytic-chart {
    margin-top: 20px;
  }
  .container .row > div {
    padding-left: 2px;
    padding-right: 2px;
  }
  .card:has(.analytic-card) {
    padding: 2px 0;
    max-height: 138px;
    min-height: 138px;
    &:not(:has(.MuiCollapse-hidden)) {
      &:has(.MuiCollapse-vertical) {
        .card-body {
          z-index: 2;
        }
        .date-picker {
          z-index: 3;
        }
      }
    }

    .card-body {

      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
        0 0 2px 0 rgba(0, 0, 0, 0.12);
    }

    .card__title {
      margin-bottom: 0;
    }
  }
  .spinner {
    position: absolute;
    bottom: 32px;
    right: 8px;
  }
  .analytic-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 5px;

    .analytic__total-extand-button {
      position: absolute;
      bottom: 4px;
      left: 2px;
    }
    .analytic__total-stat {
      color: #0a4f77;
      font-size: 14px;
      text-align: end;
    }
    .analytic__total-stat-bold {
      font-weight: 600;
      font-size: 28px;
    }
  }
  .card {
    min-height: 200px;
  }

  .date-picker {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    &-range {
      margin-left: -12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 40px);
      overflow: hidden;
    }
    svg {
      font-size: 16px;
    }
    &-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3px;
    }
  }

  .date-picker-box {
    width: 400px;
    top: 30px;
    left: 10px;
    padding: 32px 50px;
    position: absolute;
    background-color: white;
    z-index: 4;
    box-shadow: 1px 0 3px -2px rgba(0, 0, 0, 0.2),
      0px 1px 0 0 rgba(0, 0, 0, 0.14), 1px 0px 7px 2px rgba(0, 0, 0, 0.12);

    .closeIcon {
      position: absolute;
      top: 0;
      right: 0;
    }

    &-footer {
      margin-top: 20px;

      .btn.btn-lg {
        padding: 7px 22px;
      }
    }
  }

  @media (min-width: 1200px) {
    .col-xl-2:nth-child(6n) {
      @include offset-left-data-picker;
    }
  }

  @media (max-width: 1199px) {
    .col-lg-3:nth-child(4n) {
      @include offset-left-data-picker;
    }
  }

  @media (max-width: 991px) {
    .col-md-3:nth-child(4n) {
      @include offset-left-data-picker;
    }
  }

  @media (max-width: 767px) {
    .col-6:nth-child(2n) {
      @include offset-left-data-picker;
    }
  }
}
