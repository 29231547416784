.fill-container {
  position: absolute;
  z-index: 2;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  background: #fffefea1;

  .load__icon-wrap {
    margin: 0 auto;
  }
}
