//variables will be set in this page

//main colors
$default-primary-color: #004D7A; //PC Blue
//Text Colors
$default-text-color: #313c47; //dark blue gray

$header-font-family: "Catamaran", sans-serif;
$body-font-family: "Roboto", sans-serif;
$disable-block: #eee;

//themes
$color-success: #5cb85c;
$color-error: #a94442;
$color-warning: #8a6d3b;
$color-info: #31708f;
$color-danger: #a94442;

%success {
  color: #5cb85c;
  background-color: #e4ffe4;
}
%error {
  color: #a94442;
  background-color: #f2dede;
}
%danger {
  color: #a94442;
  background-color: #f2dede;
}
%info {
  color: #31708f;
  background-color: #cdecfb;
}
%warning {
  color: #8a6d3b;
  background-color: #ffebcf;
}
