.toolbar {
  .MuiButton-label {
	  font-weight: 500;
	  color: #646777;
    text-transform: lowercase,
  }

  .MuiButton-root {
    font-size: 0.675rem;
  } 
}