

.input-group {
  .select {
    width: 100%;
  }
}

.horizontal {
  label {
    display: flex;
    align-items: center;
    margin-right: 10px;
    width: 30%;
  }

  .select {
    width: 63%;
  }
}
