.margin-top_40 {
  margin-top: 40px;
}

.margin-top_50 {
  margin-top: 50px;
}

.module-list {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
