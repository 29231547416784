
.estimate-project-view {
  .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0;
  }

  .supports {
    .row {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      margin-top: 15px;
    }

    input {
      height: 32px !important;
    }
  }

  .column-reverse.MuiFormGroup-root {
    flex-direction: row;
  }
}
