.profile-form {
  .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    font-family: Roboto, sans-serif;
    font-weight: 400;
  }

  .margin-row {
    margin-right: -15px;
    margin-left: -15px;
  }

  hr {
    border: none;
    height: 20px;
    width: 100%;
    margin-top: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px -2px #eee;
    margin: -50px auto 10px;
  }
  .profile-form__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 35px 15px 35px 0;
  }

  .helioscope-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .form-group{
    .form-input {
      height: 42px;
      border-radius: 2px;
      box-shadow: none;
      z-index: 0;
    }

    label[for="selUnit"] {
      margin-bottom: 5px !important;
    }

    label[for="recieveNotification"] {
      margin-bottom: 25px !important;
    }
  }
}

.profile-wrapper {
  // height: 900px;

  .profile-wrapper__title {
    float: 'right';
    padding-right: 20px;
    margin-top: -40px;
  }

  .profile-wrapper__avatar {
    text-align: center;
    margin: 7px 0 3px 0;
  }
}

.row-div-padding {
  padding: 0;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1;
}
