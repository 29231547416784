.total-main{
  .total-top {
    display: flex;
    justify-content: space-between;
    font-weight: 900;
    font-size: 15px;
    width: 100%;
    max-width: 600px;
    margin-top: 37px;
    padding: 10px 0;

    .total-top-right {
      color: #004D7A;
      margin-right: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .total-collapse{
    width: 100%;
    max-width: 600px;

    &>div {
      margin-bottom: 4px;
    }

    td {
      padding: 10px;
    }

    tr:first-child {
      border-top: 1px solid;
    }

    .total-table-cell-bottom-border {
      border-bottom: none;
    }

    .total-table-cell-currency {
      min-width: 180px;
      &:has(span) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .total-table-last-row td{
      border-bottom: none;
    }

    .total-table-category {
      padding: 16px;
      max-width: 35px;
      background: #b5b5b5;
      border-bottom: 1px solid;
      div {
        color: black;
      }

      .total-table-category-text {
        text-align: center;
        width: 100%;
        white-space: nowrap;
        transform: rotate(-90deg);
        font-size: 11px;
        font-weight: 500;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .total-grand {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-weight: 900;
    width: 100%;
    max-width: 600px;
    font-weight: 900;
    font-size: 15px;
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: #b5b5b5;
      position: absolute;
      top: -5px;
      left: 0;
    }

    div:last-child {
      margin-right: 10px;
    }
  }
  .separateRow {
    height: 15px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
}






