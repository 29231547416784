.sm-edit{
  .props-table{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .prop-container {
    padding: 5px 25px;
    display: flex;
    width: 33%;
    align-content: space-between;
    justify-content: space-between;
    font-size: 14px;
    height: 50px;
    input{
      font-size: 14px;
      text-align: right;
    }
  }
  .props-divider {
    margin: 10px 32px;
  }

  .props-additional {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 0;
    & > * {
      margin-right: 16px;
      margin-bottom: 0;
    }
    & .MuiGrid-container {
      width: auto;
      z-index: 1;
    }
  }

  .prop-name {
    font-weight: 500;
    margin: 5px;
  }

  .prop-value {
    margin: 5px;
    text-align: right;
  }
}
