.solar-module-menu {
  .hidden{
    display: none;
  }
}

.divider {
  height: 1px;
  margin: 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
