@import "src/scss/settings/variable";
@import "src/components/common/Modal/_variables";

.card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  padding: 30px;
  height: 100%;
  border-radius: 5px;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.squared-corner-theme {
  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {
  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}

.dashboard-body {
  .dashboard-body-col {
    text-align: center;
  }

  .dashboard__total-value {
    font-size: 64px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0;
    color: $default-primary-color;
  }

  .dashboard__total-label{
    font-size: 21px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0;
    margin-top: -15px;
    color: $default-text-color;
    border-bottom: solid 1px black;
  }

  .dashboard-body-col-flex {
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .spinner{
    position: absolute;
    bottom: 40px;
    display: flex;
    flex-direction: row-reverse;
    width: 95%;
  }
}
