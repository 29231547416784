
.estimate-module {
  .MuiIconButton-root.module-add {
    padding: 0;
  }

  .horizontal {
    label {
      width: 38%;
    }

    .select {
      width: 57%;
    }
  }

  .column-reverse.MuiFormGroup-root {
    flex-direction: row;
  }

  .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0;
  }

  .toggle-horizontal {
    width: 100%;
    position: absolute;
    margin-top: -8px;
    display: flex;
    justify-content: space-between;
    &__title {
      display: flex;
      color: #69767d;
      font-size: 16px;
      .MuiSvgIcon-root {
        cursor: pointer;
      }
    }
    &__toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #69767d;
      .pc-toggle {
        display: flex;
      }
      label {
        margin-bottom: 0;
      }
      .selected {
        font-weight: bold;
      }
    }
  }
}