.form-group {
  .form-input {
    height: 42px;
    border-radius: 2px;
    box-shadow: none;
    z-index: 0;
  }
  .is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    box-shadow: none;
  }

  .is-invalidSelect {
    border: 1px solid #dc3545;
    box-shadow: none;
  }

  .invalid-feedback {
    width: 100%;
    font-size: 0.7rem;
    font-weight: 300;
    color: #dc3545;
  }


  div.text-input__block {
    height: 90px;
    & > {
      label.form-input__label {
        line-height: 1.5;
        margin-bottom: 5px;
      }
    }
  }

  .invalid-feedback {
    width: 100%;
    font-size: 0.7rem;
    font-weight: 300;
    color: #dc3545;
  }
  .width-65perc {
    width: 65%;
  }
}

.modal-header .close {
  margin-top: -2px;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}


.modal-header .close {
  margin-top: -15px;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
}

.pc-modal.modal-dialog .modal-title {
  font-family: "Catamaran", sans-serif;
  font-size: 24px;
  font-weight: 100;
  margin-top: 27px;
}

.pc-feedback-modal.pc-modal.modal-dialog .modal-title {
  font-weight: 100;
}

%pcbtn-basic-hover {
  border: solid 1px #002d47;
  background-color: #004D7A;
  color: #fff;
}
$header-font-family: "Catamaran", sans-serif;
.pcbtn-basic {
  height: 50px;
  background-color: #004D7A;
  border: solid 1px #004D7A;
  font-family: $header-font-family;
  font-size: 24px;
  color: #fff;
  width: 100%;

  &:hover,
  &:focus {
    @extend %pcbtn-basic-hover;
  }

  &:active,
  &:active:focus,
  &:active:hover {
    @extend %pcbtn-basic-hover;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}

.action-green {
  background: #0ca40f;
  color: #fff;

  &:hover {
    background: #0ec811;
  }
}

.floatLeft {
  float: left;
}

@media (min-width: 768px){
  .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-6 {
    width: 50%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }
}

.row:before, .row:after {
  display: table;
  content: " ";
  box-sizing: border-box;
  height: auto;
}

