.estimate-fields {
  .horizontal label {
    width: 30%;
  }
  
  .horizontal .select {
    width: 66.8%;
  }
  
  .form-horizontal {
    .form-group {
      display: flex;
      align-items: center;
      margin-bottom: 0px;
    }
  }

  .customer-select {
    label {
      font-weight: 700 !important;
    }
  }
}
