@import 'variables';

.pc-modal-background {
  background: rgba(18, 22, 45, 0.9);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10040;
  overflow: auto;
}

.pc-small-modal {
  width: 555px;
  height: 392px;
}

.pc-feedback-modal.pc-modal.modal-dialog {
  width: 800px;
  max-width: 800px;

  .modal-title {
    font-weight: 500;
  }

  .about {
    padding-bottom: 0;
  }

  .modal-body {
    text-align: left;
  }

  label {
    font-size: 14px !important;
    font-weight: normal !important;
    text-align: left;
    margin-bottom: 15px;
  }

  .pc-radio {
    label {
      color: #555;
    }
  }

  .modal-body {
    padding: 15px 50px;
  }

  .modal-about-description {
    padding-top: 10px;
    font-size: 14px;
  }

  .modal-footer {
    padding-bottom: 40px;

    .pcbtn {
      margin: 0px 3%;
    }
  }
}

.pc-share-modal {
  .load-list{
    text-align: center;
    font-weight: bold;
    font-size: 25px;
    &.hide{
      display: none;
    }
  }
  .shares-wrap {
    display: flex;

    & > div {
      min-width: 50%;
    }
  }

  .add-new-share {
    padding: 10px 0;

    input {
      height: 40px !important;
    }
  }

  .share-revisions-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: fit-content;
    max-height: 350px;
    overflow-y : scroll;

    .share-item {
      display: flex;
      font-size: 15px;
      margin: 10px 5px;
      align-items: center;

      &:hover {
        cursor: pointer;
        opacity: 0.75;
      }

      &.available-blocked {
        div, .material-icons {
          background-color: rgb(255, 175, 176);
          border-color: red;
        }

        div {
          color: rgb(79, 130, 59);
        }

        .material-icons {
          color: rgb(255, 2, 28);
        }
      }

      &.available-shared {
        div, .material-icons {
          background-color: rgb(225, 240, 218);
          border-color: rgb(195, 224, 182);
          color: rgb(79, 130, 59);
        }

        div {
          color: rgb(79, 130, 59);
        }

        .material-icons {
          color: rgb(0, 128, 22);
        }

      }

      &.unavailable-shared, &.unavailable-blocked, &.unavailable-blocked-shared {
        div, .material-icons {
          background-color: rgb(242, 242, 242);
          border-color: rgb(181, 181, 181);
          color: rgb(181, 181, 181);
        }
      }

      .material-icons {
        font-size: 30px;
        display: block;
        border-radius: 40px;
        border: 1px solid;
        padding: 5px;
        position: relative;
        left: 15px;
        margin-left: -15px;
      }

      div {
        border: 1px solid;
        border-radius: 20px;
        padding: 5px 15px;
        width: 100px;
        text-align: center;
      }
    }
  }

  .share-users-wrap {
    height: fit-content;
    max-height: 350px;
    overflow-y : scroll;

    .share-list-wrap {
      p {
        font-weight: bold;
      }

      .share-list-title{
        text-align: left;
        font-size: 16px;
        padding: 5px 15px 5px 15px;
        margin: 0px 0px 10px 0px;
        border-bottom: 1px lightgray solid;
      }

      .share-list {
        li {
          padding: 5px 15px 5px 15px;
          &.available:hover {
            cursor: pointer;
          }

          &.available.selected {
            font-weight: bold;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 5px 15px 5px 15px
          }
          .email{
            flex: 1 1 100%;
            margin-left: 10px;
          }
        }
      }

      #addNew {
        height: 40px !important;
      }

      ul {
        padding: 0;
        height: fit-content;
        max-height: 330px;
        overflow-y : scroll;

        li {
          padding-bottom: 10px;
          display: flex;
          align-content: center;
          font-size: 16px;

          .remove-share {
            &:hover {
              cursor: pointer;
            }

          .not-available-share {
            flex: 0 0 20px;
            &:hover {
              cursor: not-allowed;
            }
          }
            &:before {
              font-size: 20px;
              line-height: 20px;
            }
          }
        }
      }

      .share-owner {
        color: #0e9fcf;
      }
    }
  }

  .share-with-label {
    margin: 10px 0px 0px 0px;
 }
}

.pc-modal-background {

}

.pc-modal.modal-dialog {
  display: block;
  position: relative;
  box-shadow: -4px 2px 20px 0 rgba(0, 0, 0, 0.4);

  .btn:after, .btn:before {
    content: none;
  }

  .modal-content {
    height: inherit;
    width: inherit;
    -webkit-font-smoothing: antialiased;

    .lock-icon{
            font-size: 49px;
            color: rgba(49, 60, 71, 0.5);
          }
  }

  .modal-header {
    border: 0px;
    display: block;
  }

  .modal-title {
    font-family: $header-font-family;
    font-size: 24px;
    font-weight: 300;
    margin-top: 27px;
  }

  .modal-sub-title {
    font-family: $header-font-family;
    font-size: 18px;
    font-weight: 300;
    margin-top: 10px;
  }

  .modal-body {
    text-align: center;

    .modal-caption {
      margin-bottom: 21px;
    }

    .modal-media {
      margin-bottom: 18px;

      img {
        width: 25%;
      }
    }

    & p.version {
      font-size: 25px;
      font-weight: 700;
    }
  }

  .modal-footer {
    text-align: center;
    justify-content: center;
    border: none;
  }
}

.zerOLeftRightPadding {
  padding-left: 0;
  padding-right: 0;
}

.text-center{
  text-align: center;
}

.justify-content-center{
  justify-content: center;
}

.pcbtn-primary{
  color: #fff;
  background-color: #42b1f9;
  border: 1px solid #0e9fcf;
  padding: 15px 40px;
  height: 50px;
  min-width: 140px;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  user-select: none;
}

.pcbtn {
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}


hr {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

.pcbtn-default {
  color: #7c8990;
  padding: 15px 40px;
  min-width: 140px;
  background-color: #fff;
  border: solid 1px #e4e4e4;
  height: 50px;
}

btn:hover {
  text-decoration: none;
}

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}


element.style {
  position: relative;
  margin-right: 10px;
}
.pc-feedback-modal.pc-modal.modal-dialog .modal-footer .pcbtn {
  margin: 0px 3%;
}
.modal-footer .btn+.btn {
  margin-bottom: 0;
  margin-left: 5px;
}
.pcbtn-primary:active, .pcbtn-primary:active:focus, .pcbtn-primary:active:hover, .pcbtn-primary:hover, .pcbtn-primary:focus {
  border: solid 1px #0e9fcf;
  background-color: #42b1f9;
  color: #fff;
}

.form-group {
  margin-bottom: 15px;
}

.padding-btn {
  padding: 15px 40px;
}
